//////////////////
/// MARGINS
//////////////////
.margin-0{
  margin: 0 !important;
}

.margin-top-0{
  margin-top: 0 !important;
}

.margin-right-0{
  margin-right: 0 !important;
}

.margin-bottom-0{
  margin-bottom: 0 !important;
}

.margin-left-0{
  margin-left: 0 !important;
}

// MARGIN 5
.margin-5{
  margin: 5 !important;
}

.margin-top-5{
  margin-top: 5 !important;
}

.margin-right-5{
  margin-right: 5 !important;
}

.margin-bottom-5{
  margin-bottom: 5 !important;
}

.margin-left-5{
  margin-left: 5 !important;
}

// MARGIN 10
.margin-10{
  margin: 10 !important;
}

.margin-top-10{
  margin-top: 10 !important;
}

.margin-right-10{
  margin-right: 10 !important;
}

.margin-bottom-10{
  margin-bottom: 10 !important;
}

.margin-left-10{
  margin-left: 10 !important;
}


//////////////////
/// PADDING
//////////////////
.padding-0{
  padding: 0 !important;
}

.padding-top-0{
  padding-top: 0 !important;
}

.padding-right-0{
  padding-right: 0 !important;
}

.padding-bottom-0{
  padding-bottom: 0 !important;
}

.padding-left-0{
  padding-left: 0 !important;
}

// MARGIN 5
.padding-5{
  padding: 5 !important;
}

.padding-top-5{
  padding-top: 5 !important;
}

.padding-right-5{
  padding-right: 5 !important;
}

.padding-bottom-5{
  padding-bottom: 5 !important;
}

.padding-left-5{
  padding-left: 5 !important;
}

// MARGIN 10
.padding-10{
  padding: 10 !important;
}

.padding-top-10{
  padding-top: 10 !important;
}

.padding-right-10{
  padding-right: 10 !important;
}

.padding-bottom-10{
  padding-bottom: 10 !important;
}

.padding-left-10{
  padding-left: 10 !important;
}
